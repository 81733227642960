<template>
	<gc-dialog
		title="压力变化"
		width="45%"
		:show.sync="visible"
		@cancel="visible = false"
		@close="visible = false"
		:append-to-body="true"
		:show-footer="false"
	>
		<div class="query">
			<el-date-picker
				v-model="date"
				type="datetimerange"
				:picker-options="pickerOptions"
				:default-time="['00:00:00', '23:59:59']"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				@change="handleDateChange"
			></el-date-picker>
			<el-button class="btn" type="primary" @click="handleExport" v-click-blur>导 出</el-button>
		</div>
		<div class="content">
			<div id="pressureEchart" class="echart" v-if="list.length"></div>
			<gc-empty v-else></gc-empty>
		</div>
	</gc-dialog>
</template>

<script>
const echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/line')
require('echarts/lib/component/grid')
require('echarts/lib/component/tooltip')
require('echarts/lib/component/visualMap')
require('echarts/lib/component/dataZoom')
require('echarts/lib/component/markLine')
require('echarts/lib/component/markPoint')
require('echarts/lib/component/legend')
require('echarts/lib/component/title')

import { apiPressureDetail } from '@/apis/pressureAnalysis.api'
import { exportReport } from '@/apis/statisticAnalysis.api'
import { isBlank } from '@/utils/validate.js'

export default {
	name: 'pressureAnalysisDetail',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		detail: {
			type: Object,
			default: () => {},
		},
		defaultTime: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			date: null,
			list: [],
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit('pick', [start, end])
						},
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit('pick', [start, end])
						},
					},
				],
			},
		}
	},
	computed: {
		visible: {
			get() {
				return this.show
			},
			set(val) {
				this.$emit('update:show', val)
			},
		},
		pressureUnitName() {
			return this.$store.getters.pressureUnitName
		},
	},
	watch: {
		show(val) {
			if (!val) {
				this.form = this.$options.data().form
				this.$refs.form?.resetFields()
				this.chartInstance?.clear()
			} else {
				this.date = this.defaultTime
				this.getPressureDetail()
			}
		},
	},
	methods: {
		handleDateChange(e) {
			if (e) {
				this.getPressureDetail()
			}
		},
		getPressureDetail() {
			apiPressureDetail({
				iotDeviceId: this.detail.iotDeviceId,
				startDate: this.date ? this.dayjs(this.date[0]).format('YYYY-MM-DD HH:mm:ss') : null,
				endDate: this.date ? this.dayjs(this.date[1]).format('YYYY-MM-DD HH:mm:ss') : null,
			}).then(res => {
				this.list = res.records || []
				this.$nextTick(() => {
					this.renderCharts()
				})
			})
		},
		handleExport() {
			const { iotDeviceId, deviceNo } = this.detail
			if (!iotDeviceId) {
				this.$message.warning('暂无设备信息')
				return
			}
			if (!this.date) {
				this.$message.warning('请选择开始时间和结束时间')
				return
			}
			this.$toast('正在导出，请稍后...')
			const data = {
				method: 'post',
				url: '/monitor/report/pressure-analysis/detail/export/excel',
				fileName: `${deviceNo}压力分析.xlsx`,
				data: {
					iotDeviceId: iotDeviceId,
					startDate: this.date ? this.dayjs(this.date[0]).format('YYYY-MM-DD HH:mm:ss') : null,
					endDate: this.date ? this.dayjs(this.date[1]).format('YYYY-MM-DD HH:mm:ss') : null,
				},
			}
			exportReport(data, 'excel', null, this.$toast)
		},
		renderCharts() {
			let xAxisData = [],
				yAxisData = [],
				markLineData = []
			this.list.map(item => {
				xAxisData.push(item.dataDate)
				yAxisData.push(item.pressure)
			})
			const { overPressureMax, overPressureMin, underPressureMax, underPressureMin, pressureAvg } = this.detail
			if (!isBlank(overPressureMax) && overPressureMax !== pressureAvg) {
				markLineData.push({
					name: '上上限压力',
					yAxis: overPressureMax,
					label: {
						formatter: `上上限压力（${overPressureMax}${this.pressureUnitName}）`,
						position: 'insideEndTop',
					},
					lineStyle: {
						color: '#6E0317',
						width: 2,
					},
				})
			}
			if (!isBlank(overPressureMin) && overPressureMin !== pressureAvg && overPressureMin !== overPressureMax) {
				markLineData.push({
					name: '上限压力',
					yAxis: overPressureMin,
					label: {
						formatter: `上限压力（${overPressureMin}${this.pressureUnitName}）`,
						position: 'insideEndTop',
					},
					lineStyle: {
						color: '#E5615B',
						width: 2,
					},
				})
			}
			if (
				!isBlank(underPressureMax) &&
				underPressureMax !== pressureAvg &&
				underPressureMax !== overPressureMax &&
				underPressureMax !== overPressureMin
			) {
				markLineData.push({
					name: '下限压力',
					yAxis: underPressureMax,
					label: {
						formatter: `下限压力（${underPressureMax}${this.pressureUnitName}）`,
						position: 'insideEndTop',
					},
					lineStyle: {
						color: '#3BBE40',
						width: 2,
					},
				})
			}
			if (
				!isBlank(underPressureMin) &&
				underPressureMin !== pressureAvg &&
				underPressureMin !== overPressureMax &&
				underPressureMin !== overPressureMin &&
				underPressureMin !== underPressureMax
			) {
				markLineData.push({
					name: '下下限压力',
					yAxis: underPressureMin,
					label: {
						formatter: `下下限压力（${underPressureMin}${this.pressureUnitName}）`,
						position: 'insideEndTop',
					},
					lineStyle: {
						color: '#94F1C4',
						width: 2,
					},
				})
			}
			if (!isBlank(pressureAvg)) {
				markLineData.push({
					name: '平均压力',
					yAxis: pressureAvg,
					label: {
						formatter: `平均压力（${pressureAvg}${this.pressureUnitName}）`,
						position: 'insideEndTop',
					},
					lineStyle: {
						color: '#4269DF',
						width: 2,
					},
				})
			}
			this.chartInstance = echarts.init(document.getElementById('pressureEchart'))
			const option = {
				grid: {
					left: '50px',
					right: '60px',
					top: '60px',
					bottom: '60px',
					containLabel: true,
				},
				tooltip: {
					trigger: 'axis',
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: xAxisData,
				},
				yAxis: {
					type: 'value',
					name: `单位：${this.pressureUnitName}`,
					nameGap: 30,
					scale: true,
					axisLabel: {
						formatter: value => {
							if (value) return value.toFixed(0)
							return 0
						},
					},
				},
				series: [
					{
						name: `压力（${this.pressureUnitName}）`,
						type: 'line',
						areaStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: 'rgba(77, 107, 255, 0.8)' },
								{ offset: 1, color: 'rgba(77, 107, 255, 0)' },
							]),
						},
						smooth: true,
						symbol: 'none',
						data: yAxisData,
						markPoint: {
							// symbol: "roundRect",
							symbolSize: 60,
							data: [
								{ type: 'max', name: 'Max', itemStyle: { color: '#4269DF' } },
								{ type: 'min', name: 'Min', itemStyle: { color: '#4269DF' } },
							],
						},
						markLine: {
							symbol: 'none',
							silent: true,
							data: markLineData,
						},
						lineStyle: {
							color: '#4269DF',
						},
					},
				],
			}
			this.chartInstance.setOption(option)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.el-date-editor {
		width: 400px !important;
	}
}
.query {
	padding-top: 10px;
	padding-bottom: 20px;
	.btn {
		margin-left: 20px;
		height: 32px;
	}
}
.content {
	height: 450px;
	@include flex-center;
	.echart {
		width: 100%;
		height: 100%;
	}
	.gc-blank-page {
		margin: auto;
	}
}
</style>
